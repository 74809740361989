// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-for-researchers-js": () => import("./../../../src/pages/forResearchers.js" /* webpackChunkName: "component---src-pages-for-researchers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-resource-links-js": () => import("./../../../src/pages/resourceLinks.js" /* webpackChunkName: "component---src-pages-resource-links-js" */)
}

